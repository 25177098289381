<template>
  <v-card class="elevation-x">
    <v-card-text>
      <v-timeline align-top dense>
        <v-timeline-item v-for="(item, i) in items" :key="i" :color="item.color" small>
          <v-row class="pt-1">
            <v-col cols="3" class="font-alt">
              {{ item.type }}
            </v-col>
            <v-col class="font-alt">
              <div v-html="item.text" />
              <div class="caption">
                {{ item.date }}
              </div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'PatientTimeline',
    data: () => ({
      items: [
        {
          type: 'Groupe',
          text: 'Le médecin ' +
            '<strong class="primary--text">Mickael Barre</strong> ' +
            'a créé le groupe ' +
            '<strong class="primary--text">' + 'Suivi Du patient Jeanne Le-Pommellec'+ '</strong>',
          date: '31 janvier 2021',
          color: 'primary'
        },
        {
          type: 'Document',
          text: 'Le médecin ' +
            '<strong class="yellow--text text--darken-3">Guillaume Paut</strong> ' +
            'a partagé le document ' +
            '<strong class="yellow--text text--darken-3">' + 'Opération du pied'+ '</strong>',
          date: '30 janvier 2021',
          color: 'yellow darken-3'
        },
        {
          type: 'Document',
          text: 'Le médecin ' +
            '<strong class="yellow--text text--darken-3">Guillaume Paut</strong> ' +
            'a créé le groupe ' +
            '<strong class="yellow--text text--darken-3">' + 'Radio du pied'+ '</strong>',
          date: '28 janvier 2021',
          color: 'yellow darken-3'
        },
        {
          type: 'Groupe',
          text: 'Le médecin ' +
            '<strong class="primary--text">Mickael Barre</strong> ' +
            'a créé le groupe ' +
            '<strong class="primary--text">' + 'Suivi Du patient Jeanne Le-Pommellec'+ '</strong>',
          date: '1 janvier 2021',
          color: 'primary'
        },
        {
          type: 'Patient',
          text: 'Le médecin ' +
            '<strong class="secondary--text">Mickael Barre</strong> ' +
            'a modifié les informations de ce patient ',
          date: '2 décembre 2020',
          color: 'secondary'
        },
        {
          type: 'Patient',
          text: 'Le médecin ' +
            '<strong class="secondary--text">Mickael Barre</strong> ' +
            'a créé ce patient ',
          date: '2 novembre 2020',
          color: 'secondary'
        }
      ]
    })
  }
</script>
